var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._l((_vm.tree),function(item,key){return _c('div',{key:item.id,staticClass:"tree-item"},[_c('div',{class:[
        'tree-item-name',
        item[_vm.itemsName] && item[_vm.itemsName].length > 0 ? 'has-icon' : '',
      ]},[(item[_vm.itemsName] && item[_vm.itemsName].length > 0)?_c('div',{class:[
          'glyphicon',
          'glyphicon-triangle-right',
          {
            rotate: _vm.unfold[item.id],
          },
        ],on:{"click":function($event){return _vm.changeUnfold(item.id)}}}):_vm._e(),(
          _vm.showCheckbox && (item.showCheckbox == null || item.showCheckbox)
        )?_c('div',{staticClass:"selectDive",class:[
          {
            notAllChecked: !item.checked && _vm.hasChildChecked[item.id],
            disabled: item.allowCheck != null && !item.allowCheck,
          },
        ],attrs:{"type":"checkbox","checked":item.checked},on:{"click":function($event){return _vm.changeChecked(
            _vm.tree,
            item.allowCheck != null && !item.allowCheck,
            key
          )}}},[(item.checked)?_c('img',{staticClass:"iconPos",attrs:{"aria-hidden":"true","src":require("../assets/选中.png")}}):_vm._e()]):_vm._e(),(_vm.textName == 'name')?_c('span',{staticClass:"itemName",staticStyle:{"vertical-align":"top"},on:{"click":function($event){return _vm.clickChecked(_vm.tree, item.allowCheck != null && !item.allowCheck, key)}}},[_vm._v(_vm._s(item[_vm.textName]))]):_c('span',{staticClass:"itemName",staticStyle:{"vertical-align":"top"},on:{"click":function($event){return _vm.clickChecked(_vm.tree, item.allowCheck != null && !item.allowCheck, key)}}},[_vm._v(" "+_vm._s(item[_vm.textName] +(item.groupNames?'（'+item.groupNames.join("、")+'）':'（暂无表组）')))])])])}),_c('div',{staticStyle:{"height":"50px"}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <CSTabBar
                :tabbar="tabBar"
                :checked-tab="checkedTaBar"
                @changeTabBar="changeTabBar"
        ></CSTabBar>
        <div class="result-panel">
            <div class="type-panel" v-if="checkedTaBar === 1">
                <div v-for="type in ticketTypes" :key="type.type" class="type-item" v-if="![5, 9].includes(type.type)">
                    <span>{{type.name}}</span>
                    <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0"
                            @click="changeTicketTypeStatus(type.type, !type.isEnable)">{{type.isEnable ? '禁用' : '启用'}}
                    </button>
                </div>
            </div>
            <div v-if="checkedTaBar === 2">
                <div style="padding-top: 30px;margin-left: 30px">
                    <span style="padding: 0;">下载工单记录是否锁死</span>
                    <CSRadio v-model="ticketConfig.downloadExcelLock" :items="{true: '锁死', false: '不锁死'}"
                             style="margin-left: 30px;margin-top: -4px"/>
                    <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0;margin-left: 30px"
                            @click="saveTicketConfig">保存
                    </button>
                </div>
                <div style="padding-top: 10px;margin-left: 30px">
                    <span style="padding: 0;">各部门主管是否处理工单</span>
                    <CSRadio v-model="ticketConfig.executiveDirectorManage" :items="{true: '处理', false: '不处理'}"
                             style="margin-left: 30px;margin-top: -4px"/>
                    <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0;margin-left: 30px"
                            @click="saveTicketConfig">保存
                    </button>
                </div>
                <div style="margin-top: 10px;margin-left: 30px">
                    <span>其它部门（保洁/工程/保安）转单时，是否只能选择客服主管</span>
                    <CSRadio v-model="ticketConfig.onlyExecutiveDirector" :items="{true: '是', false: '否'}"
                             style="margin-left: 30px;margin-top: -4px"/>
                    <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0;margin-left: 30px"
                            @click="saveTicketConfig">保存
                    </button>
                </div>
                <div style="margin-top: 10px;margin-left: 30px">
                    <span>处理结果是否必传图片</span>
                    <CSRadio v-model="ticketConfig.resultImage" :items="{true: '必传', false: '非必传'}"
                             style="margin-left: 30px;margin-top: -4px"/>
                    <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0;margin-left: 30px"
                            @click="saveTicketConfig">保存
                    </button>
                </div>
                <div style="margin-top: 10px;margin-left: 30px">
                    <span>超时工单是否转回主管</span>
                    <CSRadio v-model="ticketConfig.timeOutSwitchBack" :items="{true: '转回', false: '不转回'}"
                             style="margin-left: 30px;margin-top: -4px"/>
                    <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0;margin-left: 30px"
                            @click="saveTicketConfig">保存
                    </button>
                </div>
                <div style="margin-top: 10px;margin-left: 30px">
                    <span>工单有效/无效操作人</span>
                    <CSSelect height="30px" i-width="27px" style="margin-left: 30px">
                        <div
                                :style="{ width: '196px', padding: '0 6px' }"
                                class="field-font"
                                @click="showStaffList = !showStaffList"
                        >
                            <template v-if="ticketConfig.ticketDecisionMaker&&ticketConfig.ticketDecisionMaker != '[]'" @click="showStaffList=true">
                                    <span style="color: black">
                                    已选择{{ ticketConfig.ticketDecisionMaker.split(',').length }}个
                                    </span>
                            </template>
                            <template v-else @click="showStaffList=true">
                                <option value="">请选择</option>
                            </template>
                        </div>
                        <div>
                            <TreePanel v-show="showStaffList" style="z-index: 100;margin-left: 190px;margin-top: 300px">
                                <div>
                                    <div class="location-search">
                                        <input
                                                type="text"
                                                placeholder="搜索手机号"
                                                class="location-input"
                                                style="width: 230px"
                                                v-model="search"
                                        />
                                        <button
                                                class="btn btn-primary font"
                                                @click="queryStaffList"
                                        >
                                            查询
                                        </button>
                                    </div>
                                </div>
                                <EditWaterEven
                                        style="height:100%;overflow-y:scroll;box-sizing: border-box"
                                        textName="name"
                                        @click="changeStaffElectric"
                                        :tree="staffList"
                                        :showCheckbox="true"
                                />
                            </TreePanel>
                        </div>
                    </CSSelect>

                    <!--                    <input type="text" v-model="ticketConfig.ticketDecisionMaker">-->
                    <button class="btn btn-primary" style="width: 50px;height: 30px;padding: 0;margin-left: 30px"
                            @click="saveTicketConfig">保存
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    import {
        queryEnableTicketTypeUrl, updateEnableTicketTypeUrl, queryTicketConfigUrl,
        editTicketConfigUrl, queryDepartmentStaffUrl, queryStaffByDutyTypesUrl, selectRecordUrl,
    } from "@/requestUrl";
    import CSTabBar from "@/components/common/CSTabBar";
    import CSRadio from "@/components/common/CSRadio";
    import EditWaterEven from "@/components/EditWaterEven";
    import TreePanel from "@/components/common/TreePanel";
    import CSSelect from "@/components/common/CSSelect";
    import {HYDROPOWER} from "@/constant";

    export default {
        name: "TicketTypeConfig",
        components: {
            CSTabBar,
            CSRadio,
            EditWaterEven,
            TreePanel,
            CSSelect
        },
        data() {
            return {
                ticketTypes: [],
                ticketConfig: {},
                checkedTaBar: 1,
                tabBar: {
                    1: "类型",
                    2: "业务",
                },
                search: null,
                staffList: [],
                showStaffList: false
            }
        },
        created() {
            this.queryTypes();
            this.queryTicketConfig();
        },
        methods: {
            async changeStaffElectric({key, source}) {

                if (source[key].checked) {
                    source[key].checked = false;
                    this.changeArray(this.ticketConfig.ticketDecisionMaker, source[key], false)
                } else {
                    source[key].checked = true
                    this.changeArray(this.ticketConfig.ticketDecisionMaker, source[key], true)
                }
            },
            queryTypes() {
                this.$fly.get(queryEnableTicketTypeUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                })
                    .then(res => {
                        this.ticketTypes = res.data;
                    })
            },
            /**
             * 修改工单类型状态
             * @param {Number} type 工单类型
             * @param {Number} enable 是否启用
             * */
            changeTicketTypeStatus(type, enable) {
                this.$CSDialog.confirm({
                    title: '提示',
                    message: `确定${enable ? '启用' : '禁用'}吗？`,
                    onConfirm: () => {
                        this.$fly.post(updateEnableTicketTypeUrl, {
                            regionCode: this.$vc.getCurrentRegion().code,
                            type,
                            isEnable: Number(enable)
                        })
                            .then(res => {
                                if (res.code === 0) {
                                    this.$vc.toast(`${enable ? '启用' : '禁用'}成功`);
                                    this.queryTypes();
                                    this.$CSDialog.instance.closeDialog();
                                }
                            })
                    }
                })
            },
            /**
             * 查询工单业务设置信息
             */
            queryTicketConfig() {
                this.$fly.get(queryTicketConfigUrl)
                    .then(res => {
                        this.ticketConfig = res.data;
                        this.queryStaffList();
                    })
            },
            saveTicketConfig() {
                console.log(this.ticketConfig)
                Reflect.deleteProperty(this.ticketConfig, "id")
                if(!this.ticketConfig.ticketDecisionMaker){
                    this.ticketConfig.ticketDecisionMaker = '[]';
                }
                this.$fly.post(editTicketConfigUrl, this.ticketConfig)
                    .then(res => {
                        if (res.code === 0) {
                            this.$vc.toast("保存成功");
                            this.showStaffList = false;
                            this.queryTicketConfig();
                        }
                    })
            },
            changeTabBar(index) {
                if (this.checkedTaBar === index) {
                    return
                }
                this.checkedTaBar = index
            },
            queryStaffList() {
                this.$fly.post(queryStaffByDutyTypesUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    departmentCodes: ["1003", "1004", "1005", "1006"],
                    search: this.search
                })
                    .then((res) => {
                        if (res.code !== 0) {
                            return;
                        }
                        this.staffList = res.data
                        console.log(11111111);
                        console.log(this.ticketConfig.ticketDecisionMaker.length);
                        if(this.ticketConfig.ticketDecisionMaker =='[]'){
                            this.ticketConfig.ticketDecisionMaker = '';
                        }
                        if (this.ticketConfig.ticketDecisionMaker&&this.ticketConfig.ticketDecisionMaker.length>0) {
                            let checkUser = JSON.parse(this.ticketConfig.ticketDecisionMaker);
                            this.staffList.forEach((item) => {
                                if (checkUser.includes(item.id)) {
                                    item.checked = true
                                }
                            })
                        }
                    });
            },
            changeArray(arr, staff, flag) {
                let staffArr = [];
                if (arr) {
                    staffArr = JSON.parse(arr)
                }
                if (flag) {
                    staffArr.push(staff.id)
                } else {
                    staffArr = staffArr.filter((item) => {
                        return item != staff.id
                    })
                }
                this.staffList = this.staffList.map(item => {
                    return {
                        ...item,
                        checked: staffArr.includes(item.id)
                    }
                })

                console.log(staffArr);
                this.ticketConfig.ticketDecisionMaker = JSON.stringify(staffArr);
                if(this.ticketConfig.ticketDecisionMaker =='[]'){
                    this.ticketConfig.ticketDecisionMaker = '';
                }
            }
        }
    }
</script>

<style lang="stylus" scoped>
    .type-panel
        width 600px
        font-size 0
        padding 20px
        min-height 200px

        .type-item
            width 148px
            font-size 14px
            margin-right 30px
            margin-bottom 20px
            display inline-flex
            justify-content space-between
            align-items center

    .location-search {
        width: 100%;
        display: flex;
    }

    .location-search .location-input {
        border-radius: 4px;
        border: 1px solid #999;
        padding: 0 10px;
        vertical-align: middle;
        width: 30px;
        height: 40px;
        box-sizing: border-box;
    }
</style>

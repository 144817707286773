<template>
  <div>
    <div class="tree-item" v-for="(item, key) in tree" :key="item.id">
      <div
        :class="[
          'tree-item-name',
          item[itemsName] && item[itemsName].length > 0 ? 'has-icon' : '',
        ]"
      >
        <div
          v-if="item[itemsName] && item[itemsName].length > 0"
          @click="changeUnfold(item.id)"
          :class="[
            'glyphicon',
            'glyphicon-triangle-right',
            {
              rotate: unfold[item.id],
            },
          ]"
        ></div>
        <div
          class="selectDive"
          v-if="
            showCheckbox && (item.showCheckbox == null || item.showCheckbox)
          "
          type="checkbox"
          :checked="item.checked"
          @click="
            changeChecked(
              tree,
              item.allowCheck != null && !item.allowCheck,
              key
            )
          "
          :class="[
            {
              notAllChecked: !item.checked && hasChildChecked[item.id],
              disabled: item.allowCheck != null && !item.allowCheck,
            },
          ]"
        >
          <!-- <svg class="icon iconPos" aria-hidden="true" v-if="item.checked">
            <use xlink:href="#icon-menushixin"></use>
          </svg> -->
          <img class="iconPos" aria-hidden="true" v-if="item.checked" src="../assets/选中.png" />
        </div>
        <span
          style="vertical-align: top"
          class="itemName"
          @click="
            clickChecked(tree, item.allowCheck != null && !item.allowCheck, key)
          "
          v-if="textName == 'name'"
          >{{ item[textName]}}</span
        >
        <span
                v-else
                style="vertical-align: top"
                class="itemName"
                @click="
            clickChecked(tree, item.allowCheck != null && !item.allowCheck, key)
          "
        >
          {{ item[textName] +(item.groupNames?'（'+item.groupNames.join("、")+'）':'（暂无表组）') }}</span
        >
      </div>
    </div>
    <div style="height:50px">

    </div>
  </div>
</template>

<script>
export default {
  name: "EditWaterEven",
  components: {},
  props: {
    tree: Array, // 数据源
    itemsName: {
      // 下级循环的数据对应的key名
      default: "children",
      type: String,
    },
    enableClickPick: {
      default: false,
      type: Boolean,
    },
    idName: {
      //
      default: "id",
      type: String,
    },
    showCheckbox: {
      // 是否显示选择框
      type: Boolean,
      default: true,
    },
    textName: {
      // 展示的文案的key名
      default: "name",
      type: String,
    },
  },
  data() {
    return {
      unfold: {},
      hasChildChecked: {},
    };
  },
  watch: {
    tree: {
      deep: true,
      handler(val) {
        val.forEach((item, index) => {
          this.$set(
            this.hasChildChecked,
            item.id,
            this.changeHasChildrenCheckedStatus(item)[0] && item.allowCheck
          );
          this.$set(
            this.tree[index],
            "checked",
            this.changeHasChildrenCheckedStatus(item)[1]
          );
        });
      },
    },
  },
  methods: {
    /**
     * 更改下级的选中状态, 用于全选，全不选的操作
     * @param {Object} item 组件中下级的数据
     * @return {Array<Boolean>}
     * 返回数组中下标为0的元素是存储下级中是否有选中的元素，如果有一个则为true
     * 返回数组中下标为1的元素是表示下级是否全部选中
     * */
    changeHasChildrenCheckedStatus(item) {
      let hasChildChecked = false,
        checkedArr = [],
        children = item[this.itemsName];
      if (children && children.length > 0) {
        children.forEach((child) => {
          if (child.checked && child.allowCheck) {
            hasChildChecked = true;
          }
          checkedArr.push(child.checked /*|| !child.allowCheck*/);
        });
        return [hasChildChecked, !checkedArr.includes(false)];
      }
      return [false, item.checked];
    },
    /**
     * 用于向父级传参
     * @param {Object}
     * {
     *     key, 选中的元素在数据中的下标
     *     source 传入到组件中的数据
     * }
     * */
    onChange({ key, source }) {
      this.$emit("change", {
        key,
        source,
      });
    },
    /**
     * 选中元素
     * @param {Object} source 传入到组件中的数据
     * @param {Boolean} isDisabled 是否是禁止选中
     * @param {Number} key 当前选中的元素的下标
     * */
    changeChecked(source, isDisabled, key) {

      this.$emit("click", {
        key,
        source,
      });
    },
    /**
     * 选中元素
     * @param {Object} source 传入到组件中的数据
     * @param {Boolean} isDisabled 是否是禁止选中
     * @param {Number} key 当前选中的元素的下标
     * */
    clickChecked(source, isDisabled, key) {
      const item = source[key];
      if (item[this.itemsName] && item[this.itemsName].length > 0) {
        this.changeUnfold(item.id);
      }
      if (!this.enableClickPick) {
        return;
      }
      if (isDisabled) {
        return;
      }
      this.$emit("change", {
        key,
        source,
      });
    },
    /**
     * 更改展开关闭状态
     * @param {Number | String} id 节点的唯一标识
     *  */
    changeUnfold(id) {
      this.$set(this.unfold, id, !this.unfold[id]);
    },
  },
};
</script>


<style lang="stylus" scoped>
.selectDive {
  padding: 0;
  width: 20px;
  height: 20px;
   border: 1px solid black;
  display: inline-block;
  border-radius: 3px;
  margin-right: 5px;
  position relative
  background-image: "../assets/未选中.png"
}
.iconPos{
    position: absolute
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
}
.tree-item {
  &-child {
    padding-left: 20px;

    &.hasCheckbox {
      padding-left: 50px;
    }
  }

  &-name {
    font-size: 24px;
    white-space: nowrap;
    cursor: pointer;

    input {
      vertical-align: middle;
      margin-right: 8px;

      &[type='checkbox'] {
        &.disabled {
          &::after {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 0;
            background-image: url('../../public/common/static/img/checkbox/disabledChecked.png');
            background-size: 105% 105%;
            background-position: center;
            display: inline-block;
            border-radius: 3px;
            border: 1px solid #979797;
            cursor: no-drop;
          }
        }
      }
    }

    span {
      vertical-align: middle;
      // width calc(100% - 50px)
      overflow: hidden;
      display: inline-block;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &.has-icon {
      span {
        max-width: calc(100% - 20px - 8px - 12px - 8px);
      }
    }
  }

  .glyphicon.glyphicon-triangle-right {
    font-size: 10px;
    margin-right: 8px;
    vertical-align: middle;
  }

  .glyphicon.glyphicon-triangle-right.rotate {
    -webkit-animation: arrowRotate 0.1s linear 1;
    animation: arrowRotate 0.1s linear 1;
    transform: rotate(90deg);
  }

  @keyframes arrowRotate {
    0% {
      -webkit-transform: rotate(0deg);
    }

    50% {
      -webkit-transform: rotate(45deg);
    }

    100% {
      -webkit-transform: rotate(90deg);
    }
  }

  @keyframes arrowRotate {
    0% {
      -webkit-transform: rotate(0deg);
    }

    50% {
      -webkit-transform: rotate(45deg);
    }

    100% {
      -webkit-transform: rotate(90deg);
    }
  }
}
</style>
